$topBarHeight: 138px;
$topBarHeightMobile: 90px;

$blue: #17A9ED;
$darkBlue: #3B596E;
$white: #FFFFFF;
$black: #12110D;
$gray: #9B9B9B;
$red: #DA615B;

