@import 'src/comonStyles/variables.scss';

.quantitySelector {
  margin-bottom: 40px;
  width: 90px;
  display: flex;
  flex-direction: row;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  height: 40px;
  align-items: center;
  font-size: 14px;
  div {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    cursor: pointer;
    color: #c4c4c4;
    user-select: none;
  }
}
