@import 'src/comonStyles/variables.scss';

.format {
  border: 1px solid #CEE1E8;
  border-radius: 1px;
  padding: 5px;
  margin-top: 5px;
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  white-space: nowrap;
  overflow: visible;
  cursor: pointer;

  .name {
    display: inline-flex;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    height: 16px;
  }

  .icon {
    margin-left: 5px;
    display: inline-flex;
  }
}
