@import 'src/comonStyles/variables.scss';

.container {
  display: flex;
  align-items: center;
}

.item {
  margin-right: 6px;
  font-size: 14px;
  line-height: 16px;
}

.label {
  color: #757575
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12px;
}

.mobileItem {
  margin-bottom: 10px;
}

.priceDetails {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}