@import 'src/comonStyles/variables.scss';

.rightSection {
  display: inline-flex;
  height: 100%;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  button {
    margin-left: 10px;
  }
}
