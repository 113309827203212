.frameSelectorPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  max-width: 405px;
  width: 80%;
  margin-bottom: 20px;
}

.withoutFrame {
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 5px 25px;
  height: auto;
}

.withFrame {
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 5px 25px;
  height: auto;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}