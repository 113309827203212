@import 'src/comonStyles/variables.scss';

.price {
  white-space: nowrap;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: $darkBlue;
  margin-left: 10px;

  span {
    margin-right: 5px;
  }

  .crossed {
    text-decoration: line-through;
  }

  .red {
    color: red;
  }
}
