.sticker {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.imageContainer {
  position: relative;
  margin-right: 5px;

  img {
    height: 36px;
    width: 36px;
    background-color: #f2f2f2;
    border-radius: 50%;
  }

  span {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #5C94FC;
    height: 16px;
    width: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    border-radius: 14px;
  }
}

.priceContainer {
  display: flex;
  flex-direction: column;
}

.price {
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.breakdown {
  color: #757575;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  margin-top: 2px;
  margin-right: 2px;

  span {
    margin-right: 0px;
  }
}

.breakdownContainer {
  display: flex;
  flex-direction: row;
}