@import 'src/comonStyles/variables.scss';

.editor {
  flex: 1 1 auto;
  position: relative;

  iframe {
    display: flex;
    flex: 1;
    width: 100%;
    position: absolute;
  }

  .noEvents {
    pointer-events: none;
  }

  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index:100;
    background: white;
    padding: 10px;
    border-radius: 10px;
    display: inline-flex;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0; // fix for Safari
}
