.popupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-bottom: 20px;
}

.withFrame {
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 5px 25px;
  height: auto;
}
