.carousel {
  display: flex;
  overflow: hidden;
}

.carouselItems {
  display: flex;
  width: 100px;
  flex-grow: 1;
  height: 112px;
  &.mobile {
    overflow: scroll;
  }
}

