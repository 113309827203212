@import 'src/comonStyles/variables.scss';

.dropdown {
  border-radius: 6px;
  background-color: $white;
  padding: 12px;
  z-index: 999;
  -webkit-box-shadow: 10px 10px 22px -1px rgba(0,0,0,0.25);
  -moz-box-shadow: 10px 10px 22px -1px rgba(0,0,0,0.25);
  box-shadow: 10px 10px 22px -1px rgba(0,0,0,0.25);
}
