@import 'src/comonStyles/variables.scss';

.dropdownContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.positionDesktop {
  top: 20px
}

.positionMobile {
  top: 50px;
  width: 95vw;
  left: 12px;
}

.children {
  display: flex;
  flex-direction: column;
}
