.voucherText {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  background: #EB5757;
  width: 100%;
  text-align: center;
  padding: 12px 0;
  margin-bottom: 25px;
  img {
    max-height: 310px;
  }
}