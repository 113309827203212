.frameSelector {
  width: 90%;
  margin-bottom: 20px;
  position: relative;

  .selectorArea {
    padding-left: 55px;
    padding-right: 55px;
    &.mobile {
      padding: initial;
    }
  }
  .addFrameTitle {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .frame {
    box-sizing: border-box;
    width: 66px;
    height: 66px;
    border: 1px solid #E0E0E0;
    padding: 2px;
    display: inline-flex;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;

    span {
      font-size: 13px;
      color: #4f4f4f;
      background: rgba(196, 196, 196, 0.2);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      object-fit: contain;
      width: 60px;
      height: 60px;
    }
    &.active {
      border: 2px solid #17A9ED;
      img {
        width: 58px;
        height: 58px;
      }
    }
  }
}

.price {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  font-weight: normal;
}
