.popup {
  z-index: 100000;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0;
  width: var(--popup-width);

  &.mobile {
    border: none;
    box-shadow: none;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transform: initial;
    border: none;
    width: auto;

    &.mobileFloat {
      left: 21px;
      right: 21px;
      top: 50%;
      transform: translateY(-50%);
      height: fit-content;
    }  
  };

  &.rounded {
    padding: 0;
    border-radius: 16px;
  }

  .closeIcon {
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 0;
    cursor: pointer;
  }

  .header {
    border-bottom: 1px solid #e0e6e9;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    text-transform: capitalize;
    &.desktop {
      padding-top: 30px;
      padding-bottom: 20px;
      border-bottom: none;
      font-size: 22px;
    }
  }

  .popupContent {
    overflow-y: auto;
    flex: 1;
    padding: 10px 0;
  }

  &.rounded {
    .popupContent {
      padding: 0;
    }
  }

  .applyButton {
    cursor: pointer;
    text-align: center;
    padding: 10px;
    margin: 10px 20px;
    color: #fff;
    background: #333333;
    text-transform: uppercase;
    font-weight: 700;
  }
}
