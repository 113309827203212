.quantitySelectPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.columns {
  width: 85%;
  display: flex;
  flex-direction: row;
}

.detailsColumn {
  flex-grow: 1;
}

.detailsRow {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.rows {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.subTitle {
  font-size: 16px;
  &.mobile {
    margin-bottom: 30px;
  }
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-bottom: 20px;
}

.totalPrice {
  margin-top: 10px;
  margin-bottom: 20px;
}

.preview {
  &.mobile {
    max-width: 125px;
    max-height: 200px;
    img {
      max-width: 125px;
      max-height: 200px;
    }
  }
}