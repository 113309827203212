.popupWrapper {
  --popup-width: 600px;
}

.container {
  padding: 0;
}

.warning {
  display: flex;
  width: calc(100% - 48px);
  justify-content: flex-start;
  align-items: center;
  padding: 20px 18px;

  op-icon {
    margin-right: 10px;
  }
}

.message {
  border-top: 1px solid var(--op-color-neutral-20);
  border-bottom: 1px solid var(--op-color-neutral-20);
  padding: 16px 24px;
}

.tips {
  margin: 16px 0;
  background: #D8E8F6;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tipsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tipWithIcon {
  display: flex;
  align-items: center;
  gap: 8px;

  op-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: var(--op-color-primary-50);
    border-radius: 8px;
  }
}

.confirmation {
  display: flex;
  gap: 8px;
}

.actions {
  padding: 24px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: nowrap;
}

.action {
  width: 50%;
}

.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;

  .warning {
    padding: 13px 18px;
  }
  
  .actions {
    width: calc(100% - 48px);
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .action {
    width: 100%;
  }

  .action--primary {
    margin-left: 0;
    margin-top: 12px;
  }
}
