@import 'src/comonStyles/variables.scss';

.button {
  box-sizing: border-box;
  min-width: 32px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid $blue;
  cursor: pointer;
  outline: none;
  background: transparent;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;

  font-family: Geato Sans, Arial, sans-serif;
  font-size: 14px;
  color: $blue;
  stroke: $blue;

  span {
    display: flex;
    height: 32px;
    align-items: center;
  }

  svg {
    margin-right: 10px;
    stroke: $blue;
    height: 32px;
    width: 32px;
  }

  &.disabled {
    border-color: $gray !important;
    background: $gray !important;
    color: $white !important;
    * {
      stroke: $white !important;
    }
  }

  &.withPadding {
    padding: 0 25px;
  }

  &:not(.disabled):hover {
    //background: $buttonHoverBackground;
    //border: 1px solid $buttonHoverBackground;

    &:active {
      //background: $buttonActiveBackground;
      //border: 1px solid $buttonActiveBorder;
    }
  }

  &.on {
    //background: $buttonActivatedBackground;
    //border: 1px solid $buttonActivatedBorder;

    &:not(.disabled):hover {
      //background: $buttonActivatedHoverBackground;
      //border: 1px solid $buttonActivatedHoverBorder;

      &:active {
        //background: $buttonActivatedActiveBackground;
        //border: 1px solid $buttonActivatedActiveBorder;
      }
    }
  }

  &.primary {
    color: $white;
    background: $blue;

    * {
      stroke: $white;
    }
  }
}
