@import 'src/comonStyles/variables.scss';

.bundledProduct {
  font-size: 12px;
  color: $black;
}

.price {
  font-weight: normal;
  color: $black;
  font-size: 12px;
}
