@import "src/comonStyles/variables.scss";

.centerSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  flex-direction: column;
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .attributesAndPrice {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 5px;
  }
  .priceInline {
    display: inline-block;
    margin-left: 5px;
    font-size: 13px !important;
  }
  .attributes {
    margin-left: 12px;
  }
  .baseInfo {
    margin-bottom: 12px;
    align-items: flex-end;
  }
  .baseInfoMobile {
    margin-bottom: 5px;
  }
  .infoContainer {
    margin-bottom: 6px;
    display: flex;
  }
}

.priceBreakdownDesktop {
  display: flex;
  align-items: center;
}

.totalPriceDesktop {
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
}

.showAll {
  background-color: transparent;
  border: 0;
  color: $blue;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0;

  span {
    margin-right: 5px;
  }
}

.dropdown {
  position: relative;
}

.totalPrice {
  font-size: 14px;
}

.designPrice {
  margin-left: 2px;
}

.productAttributesMobile {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}