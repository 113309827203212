@import 'src/comonStyles/variables.scss';

.topBar {
  flex: 0 1 $topBarHeight;
  display: flex;
  padding: 6px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(153, 173, 184, 0.2);


  &.mobile {
    flex: 0 1 $topBarHeightMobile;
    flex-direction: column;
    position: relative;

    .buttons {
      position: absolute;
      top: 5;
      left: 0;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
      display: flex;
      button {
        padding: 0px;
      }
    }

    .descriptions {
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      justify-content: center;
    }

    svg {
      margin: 0;
    }

  }
}
