@import 'src/comonStyles/variables.scss';

.format {
  min-width: 200px;
  display: flex;
  border: 1px dotted gray;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  width: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  &:hover {
    border: 1px solid gray;
  }

  div {
    display: inline-flex;
    width: auto;
    white-space: nowrap;
    overflow: visible;


    &.checkbox {
      margin-right: 5px;
    }

    &.nameAndPrice {
      display: inline-flex;
      flex-direction: column;
      .from {
        font-size: 12px;
        color: $gray;
        margin-right: 5px;
        align-items: center;
      }
      div {
        display: flex;
      }
    }
  }
}
