.popupWrapper {
  --popup-width: 600px;
}

.container {
  padding-top: 88px;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 12px;
}

.warning {
  display: flex;
  width: 100%;
  justify-content: center;
}

.warning__message {
  font-family: 'Gelato Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 34px;
  color: #333333;
  margin-left: 16px;
  vertical-align: center;
}

.message {
  font-family: 'Gelato Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #333333;
  text-align: center;
  margin-top: 24px;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
}

.action {
  width: 343px;
  height: 44px;
  font-family: 'Gelato Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}

.action--primary {
  background: #17A9ED;
  border: none;
  color: #FFFFFF;
}

.action--secondary {
  color: #17A9ED;
  background: #FFFFFF;
  border: none;
}

.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  .message {
    width: 262px;
  }

  .actions {
    flex-direction: column;
    align-items: center;
  }

  .action {
    width: 262px;
  }

  .action--primary {
    margin-left: 0;
    margin-top: 12px;
  }
}
