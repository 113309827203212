.container {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  row-gap: 8px;
  column-gap: 18px;
  justify-content: space-between;
}

.sticker {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 32px;
    width: 36px;
    margin-right: 5px;
  }
}