@import 'src/comonStyles/variables.scss';

.title {
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  color: $black;
  text-align: center;
}

.fontSizeDesktop {
  font-size: 18px;
}

.fontSizeMobile {
  font-size: 3.6vw;
}