.productPreview {
  max-height: 200px;
  max-width: 200px;
  margin-bottom: 30px;

  img {
    max-height: 200px;
    max-width: 200px;
  }

  &.mobile {
    max-height: 160px;

    img {
      max-height: 160px;
    }
  }
}